import React, { useCallback, useEffect, useState } from 'react';
import StockConsumption from '../../../../../forms/stockConsumption';
import CrudTable from '../../../../../components/crudTable';
import {
  BackButton,
  Container,
  StyledMoneyInput,
  StyledTypography,
  Button,
  SaveIcon,
} from './styles';
import restApiClient from '../../../../../services/restApiClient';
import FilterTree from '../../../../../helpers/filterTree';
import moment from 'moment/moment';
import sumBy from 'lodash/sumBy';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { toFixed } from '../../../../../extensions/object';

function ManageStockConsumptionUnit(props) {
  const stockConsumptionId = parseInt(props.match?.params?.id ?? -1);
  const noEdit = props.location?.state?.noEdit ?? false;
  const unitId = parseInt(props.match?.params?.unitId ?? -1);
  const beginDate = props.location?.state?.beginDate;
  const endDate = props.location?.state?.endDate;
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState({ count: 0, values: [] });
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [orderBy, setOrderby] = useState({ id: 'asc' });
  const [searchBy, setSearchBy] = useState('');
  const [total, setTotal] = useState(0);
  const [itemList, setItemList] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const columns = StockConsumption.groupsConsumptionTableSchema;

  const loadItems = async () => {
    const result =
      await restApiClient.stockConsumptionValue.getAllByStockConsumptionId(
        stockConsumptionId,
        rowsPerPage,
        page * rowsPerPage,
        searchBy
          ? new FilterTree().like('group.name', searchBy).toString()
          : null,
        orderBy
      );
    if (result.data.count > 0) {
      let newRows = result.data;
      const originalList = newRows.values;
      newRows.values = result.data.values.map((item) => ({
        ...item,
        name: item.group.name,
        profitMargin: !noEdit ? item.stockProfitMargin : item.profitMargin,
      }));

      if (isEmpty(itemList)) setItemList(cloneDeep(newRows.values));
      return [originalList, newRows];
    } else {
      const e =
        await restApiClient.stockConsumption.getAllGroupsConsumptionByPeriod(
          moment(beginDate).format('DD-MM-YYYY'),
          moment(endDate).format('DD-MM-YYYY'),
          unitId,
          rowsPerPage,
          page * rowsPerPage,
          searchBy ? new FilterTree().like('name', searchBy).toString() : null,
          orderBy
        );
      let newRows = e.data;
      newRows.values = e.data.values.map((item) => ({
        ...item,
        id: undefined,
        groupId: item.id,
        correction: 0,
      }));

      if (isEmpty(itemList)) setItemList(cloneDeep(newRows.values));
      return [newRows.values, newRows];
    }
  };

  useEffect(() => {
    loadItems().then(([originalList, rows]) => {
      setOriginalList(originalList);
      setRows(rows);
    });
  }, [
    rowsPerPage,
    page,
    orderBy,
    searchBy,
    beginDate,
    endDate,
    unitId,
    stockConsumptionId,
  ]);

  useEffect(() => {
    setTotal(
      sumBy(originalList, (item) => {
        let obj, totalProfit;
        if ((obj = itemList.find((i) => i.groupId === item.groupId))) {
          totalProfit = toFixed(
            parseFloat(obj.subtotal) +
              (parseFloat(obj.subtotal) * obj.profitMargin) / 100,
            2
          );
        } else {
          totalProfit = 0;
        }
        return totalProfit;
      })
    );
  }, [originalList, itemList]);

  const salvar = () => {
    loadItems().then(([originalList]) => {
      const added = itemList.filter((item) => !item.id);

      const edited = itemList
        .filter(
          (item) =>
            item.id &&
            (originalList.find((obj) => item.id === obj.id)?.correction !==
              item.correction ||
              originalList.find((obj) => item.id === obj.id)?.profitMargin !==
                item.profitMargin)
        )
        .map((item) => ({
          id: item.id,
          correction: item.correction,
          subtotal: item.subtotal,
          profitMargin: item.profitMargin,
          totalProfit: item.totalProfit,
        }));

      restApiClient.stockConsumption
        .update(stockConsumptionId, {}, {}, added, edited)
        .then(() => {
          restApiClient.stockConsumption
            .setEditing(stockConsumptionId)
            .then(() => props.history.goBack());
        });
    });
  };

  return (
    <Container>
      <CrudTable
        title={`Consumo - ${moment(beginDate).format('DD/MM/YYYY')} à ${moment(
          endDate
        ).format('DD/MM/YYYY')}`}
        customInfosComponent={
          <StyledTypography variant="h6">
            Valor total:{' '}
            {(!isNaN(total) ? total : 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          </StyledTypography>
        }
        customFooterComponent={
          <>
            <BackButton
              onClick={() => props.history.goBack()}
              type="button"
              variant="contained"
              color="primary"
            >
              Voltar
            </BackButton>
            {!noEdit && itemList.length > 0 ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={async () => {
                  await salvar();
                }}
              >
                Salvar
              </Button>
            ) : null}
          </>
        }
        modelActions={StockConsumption.actions}
        columns={columns}
        rows={rows}
        page={page}
        setPage={(page) => setPage(page)}
        availableRowsPerPage={[15]}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        onOrderByChange={(orderBy) => setOrderby(orderBy)}
        orderBy={orderBy}
        setOrderBy={(value) => setOrderby(value)}
        searchBy={searchBy}
        setSearchBy={(value) => setSearchBy(value)}
        customCellRenderers={{
          transfer: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          adjustment: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          loss: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          correction: {
            renderer: (field, value, column, row) => {
              row.correction = parseFloat(row.correction ?? 0);
              let itemFind = itemList.find((x) => x.groupId === row.groupId);
              let correction = 0;
              if (itemFind) correction = itemFind?.correction;

              if (!noEdit) {
                return (
                  <StyledMoneyInput
                    value={correction || 0}
                    onChange={(e, value) => {
                      let item;
                      let correction = parseFloat(value ?? 0);
                      const newList = [...itemList];
                      if (
                        (item = newList.find((x) => x.groupId === row.groupId))
                      ) {
                        item.correction = correction;
                        item.subtotal =
                          parseFloat(item.transfer ?? 0) +
                          parseFloat(item.loss ?? 0) +
                          parseFloat(item.adjustment ?? 0) +
                          correction;
                      }
                      setItemList(newList);
                    }}
                    onClick={(e) => {
                      let value = parseFloat(
                        e.target.value.substring(3).replace(',', '.')
                      );
                      if (value === 0) e.target.value = '';
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'center',
                      },
                    }}
                    allowNegative
                  />
                );
              } else {
                value = parseFloat(value ?? 0);
                return value.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
              }
            },
          },
          subtotal: {
            renderer: (field, value, column, row) => {
              let subtotal;
              let itemFind = itemList.find((x) => x.groupId === row.groupId);
              if (itemFind) {
                subtotal = parseFloat(itemFind.subtotal);
              } else {
                subtotal = value;
              }

              return (!isNaN(subtotal) ? subtotal : 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
          profitMargin: {
            renderer: (field, value) => {
              value = parseFloat(value ?? 0);
              return `${value}%`;
            },
          },
          totalProfit: {
            renderer: (field, value, column, row) => {
              row.totalProfit = parseFloat(row.totalProfit ?? 0);
              let totalProfit;
              let itemFind = itemList.find((x) => x.groupId === row.groupId);
              if (itemFind) {
                let subtotal = parseFloat(itemFind.subtotal);
                totalProfit = toFixed(
                  subtotal + (subtotal * row.profitMargin) / 100,
                  2
                );
                itemFind.totalProfit = totalProfit;
              }

              return parseFloat(totalProfit).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        }}
      />
    </Container>
  );
}

export default ManageStockConsumptionUnit;
